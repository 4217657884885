<script setup lang="ts">
const year = ref(new Date().getFullYear())
</script>

<template>
  <div class="border-t border-forest-700 py-8">
    <div class="container-8xl">
      <div class="md:flex w-full items-center justify-between">
        <div>
          <p class="text-xs text-forest-50 mb-0">
            &copy; {{ year }} Butler Sherborn. All rights reserved.<br>
            Butler Sherborn LLP is a Limited Liability Partnership.<br>
            43-45 Castle Street, Cirencester, Gloucestershire, GL7 1QD.<br>
            Registered in England and Wales No. OC419932. VAT: 596145117.<br>
            Regulated by RICS.
          </p>
        </div>
        <div class="text-xs text-white mt-4 md:mt-0 mb-0 flex items-center">
          <nuxt-link nofollow href="http://mrdannyjohnson.co.uk" class="opacity-70 hover:opacity-100">
            <span class="">Property web design</span>
            <Icon name="clarity:asterisk-solid" class="h-3 w-3 mx-0.5 mb-0.5" />
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>
