<script setup lang="ts">
const { urlFor } = useSanityImage()

const membershipsQuery = groq`*[_type == "memberships" && membership == true]{
  title,
  image,
  slug,
  membership,
  image{
    "asset": {
      "_ref": asset._ref,
      "metadata": {
        "dimensions": {
          "width": asset->metadata.dimensions.width,
          "height": asset->metadata.dimensions.height
        }
      }
    }
  }
} | order(orderRank)`

const sanity = useSanity()
const { data: memberships, error } = await useAsyncData('membership-logos', () => {
  return sanity.fetch<Sanity.Default.Schema.Memberships[]>(membershipsQuery)
})
// // console.log(memberships.value)

if (error.value) {
  // console.log('Error fetching memberships')
  console.error(error)
}
</script>

<template>
  <template v-if="memberships">
    <div class="py-8 border-t border-stone-300 bg-stone-200">
      <div class="md:w-5/6 lg:w-full container-8xl">
        <div class="grid gap-4 md:gap-6 grid-cols-3 md:flex md:flex-shrink-0 md:flex-row lg:gap-8 justify-center">
          <div
            v-for="membership in memberships"
            :key="membership?.slug.current"
            class="flex items-center justify-center"
          >
            <nuxt-img
              :src="urlFor(membership?.image?.asset._ref).width(128).url()"
              :alt="`${membership?.title} logo`"
              :width="membership?.image?.asset.metadata.dimensions.width"
              :height="membership?.image?.asset.metadata.dimensions.height"
              class="shrink-0 max-h-8 lg:max-h-16 md:max-w-32 lg:max-w-56 object-contain w-auto grayscale-[0.33] mix-blend-multiply"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
