<template>
  <div class="py-10 border-b border-forest-700">
    <div class="container-8xl">
      <div class="lg:flex items-center justify-between">
        <div class="mb-3 lg:mb-0">
          <p class="font-semibold mb-0">
            <span class="text-lg lg:text-2xl text-gray-50 text">Sign up to the mailing list</span><br>
            <span class="text-base lg:text-xl text-forest-100 font-normal">Keep up to date with the latest properties, rural business insights, market commentary and events.</span>
          </p>
        </div>
        <div class="">
          <UiBtn
            size="xl"
            to="https://butlersherbornllp.briefyourmarket.com/NewProfile.aspx"
            label="Join mailing list"
            rel="noopener noreferrer nofollow"
          />
        </div>
      </div>
    </div>
  </div>
</template>
