<script setup lang="ts">
</script>

<template>
  <FooterMemberships />
  <footer aria-labelledby="footer-heading" class="bg-forest-800 text-gray-50">
    <FooterMailingList />
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>
    <div class="container-8xl hidden lg:block">
      <LazyFooterDesktopMenus />
    </div>
    <div class="container-8xl block lg:hidden">
      <LazyFooterMobileMenus />
    </div>
    <FooterLower />
  </footer>
</template>
